<template>
  <div>
    <Title :shadow="!selectedItem" :loading="loading">{{ $t("chatSidebar.freight") }}</Title>
    <div class="chatWrapp">
      <v-list v-if="!selectedItem" class="py-0">
        <v-list-item-group>
          <v-list-item v-for="group in threads" :key="group.threadType" class="market" @click="open(group)">
            <v-list-item-content class="w-100">
              <v-list-item-title class="" style="font-size: 12px"><span class="primary--text">{{ group.description }} ({{ group.count }})</span></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <div v-else>
        <Offers :group="selectedItem" @close="closeOffers" />
      </div>
    </div>
    </div>
</template>
<script>
import Service from '@/services'
import Title from '../Shared/Title.vue'
import * as thread from '../../types/thread-type.js'
import Offers from '../Shared/Offers.vue'
import * as types from '@/store/modules/chat/chat-mutation-types'
import * as list from '@/types/chat-list'
export default {
  name: 'Freight',
  components: { Title, Offers },
  data () {
    return {
      loading: false,
      threads: null,
      selectedItem: null,
      threadsName: [
        { threadType: thread.FreightOrders, description: this.$t('chatSidebar.freightOrders') },
        { threadType: thread.MyFreightOrders, description: this.$t('chatSidebar.freight') }
      ]
    }
  },
  computed: {},
  async created () {
    this.$store.commit(`chat/${types.CLEAR_NEW}`, list.newFreight)
    await this.getFreightThreads()
  },
  methods: {
    async getFreightThreads () {
      this.loading = true

      try {
        const { data } = await Service.get('/Chat/GetFreightThreadsDisplayData')
        this.threads = data.map((item) => {
          const thread = this.threadsName.find(
            (t) => t.threadType === item.threadType
          )

          if (thread) {
            return { ...item, description: thread.description }
          }

          return { ...item, description: '' }
        })
      } catch (e) {}

      this.loading = false
    },
    open (group) {
      this.selectedItem = group
    },
    closeOffers () {
      this.selectedItem = null
    }
  }
}
</script>
<style scoped>
.market:after {
  content: "";
  border-bottom: 2px solid #E6E6E6;
  bottom: 0px;
  left: 15px;
  width: calc(100% - 30px);
  position: absolute;
}
</style>
